import {
  Alert,
  FormControlLabel,
  FormHelperText,
  Skeleton,
  Stack,
  Switch, Tooltip,
  Typography,
} from '@mui/material';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';

import {useEffect} from 'react';
import useLocale from '../../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../../utils/localization';
import {Error} from '@mui/icons-material';
import useDefaults from '../../../hooks/defaults/useDefaults';

function WalletSelect({
                        isLoading,
                        walletData,
                        balanceTitle,
                        balanceValueProps,
                        onChange=()=>{}
                      }) {

  const {t} = useTranslate();
  const {control, setValue} = useFormContext();
  const {currencySymbol} = useDefaults();                      
  const {locale} = useLocale();
  dayjs.locale(locale);

  const haveWalletData = !!walletData;
  const isWalletAvailable = walletData?.available;
  const walletBalance = String(walletData?.amount); // convert to string so 0 amount is treated as truthy
  const walletHasSufficientFunds = walletData?.canPay;
  const isWalletDisabled = !isWalletAvailable  || !walletHasSufficientFunds;
  // console.log('walletData',walletData)
  useEffect(() => {
    if (!walletHasSufficientFunds) {
      setValue('wallet', false);
    }
  }, [walletHasSufficientFunds]);

  if (isLoading) {
    return (
        <Skeleton height={'24px'} width={'300px'}/>
    );
  }

  if (!isLoading) {
    return (
        <Stack>
          <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
          >
            {
                walletBalance &&
                <Stack>
                  <Typography
                      variant={'h6'}
                      color={isWalletDisabled ?
                          'text.disabled' :
                          'text.primary'}
                    {...balanceValueProps}
                  >
                    {`${currencySymbol}${getLocalizedNum(locale,
                        +walletBalance)}`}
                  </Typography>
                </Stack>
            }
            <Stack>

              <Stack direction={'row'} alignItems={'center'} spacing={.5}>
                {
                    (!isLoading && haveWalletData && isWalletAvailable &&
                        !walletHasSufficientFunds) &&
                    <Tooltip title={t('wallet.alert.insufficientFunds')} placement={'top'} arrow>
                      <Error color={'action'}/>
                    </Tooltip>
                }
                {
                    (!isLoading && haveWalletData && !isWalletAvailable)  &&
                    <Tooltip title={t('wallet.alert.walletNotAvailable')} placement={'top'} arrow>
                      <Error color={'action'}/>
                    </Tooltip>
                }
                <Controller
                    name={'wallet'}
                    control={control}
                    defaultValue={false}
                    render={({field}) => {
                      return (
                          <FormControlLabel
                              label={balanceTitle??t('global.components.walletToggle.label')}
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                              }}
                              control={<Switch
                                  {...field}
                                  disabled={isWalletDisabled}
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    onChange()
                                  }}
                              />}
                          />);
                    }}
                />

              </Stack>
            </Stack>
          </Stack>
        </Stack>
    );
  }

}

export default WalletSelect;
