import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowDropDown,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  MenuRounded,
} from '@mui/icons-material';
import {createContext, useContext, useState} from 'react';
import SiteLanguageSwitcher from './SiteLanguageSwitcher';
import ProfileImageViewer from '../Profile/ProfileImageViewer';
import {useNavigate} from 'react-router-dom';
import useUser from '../../../hooks/access/useUser';
import {useTranslate} from '@tolgee/react';
import useLogout from '../../../hooks/onboarding/useLogout';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import DialogLogin from '../../../dialogs/OnBoarding/DialogLogin';
import DialogForgotPassword
  from '../../../dialogs/OnBoarding/DialogForgotPassword';
import DialogRegister from '../../../dialogs/OnBoarding/DialogRegister';
import useOnboardingContext
  from '../../../hooks/onboarding/useOnboardingContext';
import {loginEvents} from '../../../utils/analyticsEvents';
import IconMenu from '../../../assets/icons/IconMenu';

const MobileNavContext = createContext();

function SiteMobileNav({navItems, color = '#fff'}) {

  const [isMobileNavDrawerOpen, setIsMobileNavDrawerOpen] = useState(false);
  const theme = useTheme();

  const user = useUser();
  const isAuthenticated = useIsAuthenticated();

  function handleOpenDrawer() {
    setIsMobileNavDrawerOpen(true);
  }

  function handleCloseDrawer() {
    setIsMobileNavDrawerOpen(false);
  }

  return (
      <MobileNavContext.Provider value={{handleOpenDrawer, handleCloseDrawer}}>
        <>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleOpenDrawer}
          >
            <IconMenu/>
          </Box>
          <SwipeableDrawer
              anchor={'right'}
              open={isMobileNavDrawerOpen}
              onClose={handleCloseDrawer}
              onOpen={handleOpenDrawer}
          >
            <Stack
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                borderBottom={`1px solid ${theme.palette.divider}`}
                minWidth={'80vw'}
            >
              <IconButton
                  onClick={handleCloseDrawer}
                  sx={{mr: 'auto'}}
              >
                <ChevronRight fontSize={'large'} color={'action'}/>
              </IconButton>
              <Box pr={2}>
                <SiteLanguageSwitcher/>
              </Box>
            </Stack>
            {
                user &&
                <UserProfile user={user}/>
            }
            <Divider/>
            <NavList isAuthenticated={isAuthenticated} navItems={navItems}
                     disablePadding={true}/>

            {
                !user &&
                <LoginSignupButtons
                    setIsMobileNavDrawerOpen={setIsMobileNavDrawerOpen}
                />
            }

          </SwipeableDrawer>
        </>
      </MobileNavContext.Provider>
  );
}

function LoginSignupButtons({setIsMobileNavDrawerOpen}) {

  const theme = useTheme();
  const {t} = useTranslate();

  const {
    isOpenLoginDialog,
    setIsOpenLoginDialog,
    loginSuccessFn,
    loginSuccessFnParams,
    setLoginSuccessFnParams,
    onLoginDialogCloseParams,
    setOnLoginDialogCloseParams,
    isOpenForgotDialog,
    setIsOpenForgotDialog,
    isOpenRegisterDialog,
    setIsOpenRegisterDialog,
    registerSuccessFn,
    registerSuccessFnParams,
    onRegisterDialogCloseFn,
    onRegisterDialogCloseParams,
    setOnRegisterDialogCloseParams,
    willShowForgotPassword,
    setWillShowForgotPassword,
    willShowLoginDialog,
    setWillShowLoginDialog,
    willShowRegisterDialog,
    setWillShowRegisterDialog,
    preFillEmail,
    setPreFillEmail,
  } = useOnboardingContext();

  function handleOpenLoginDialog() {
    setIsOpenLoginDialog(true);
    loginEvents['openLoginPopup']();
    setIsMobileNavDrawerOpen(false);
  }

  function handleOpenRegisterDialog() {
    setIsOpenRegisterDialog(true);
    setIsMobileNavDrawerOpen(false);
  }

  const loginSuccessHandler = () => {
  };

  const registerSuccessHandler = () => {

  };

  const onCloseHandler = () => {

    if (onRegisterDialogCloseParams) {
      onRegisterDialogCloseFn(onRegisterDialogCloseParams);
    }
  };

  return (
      <>
        <List disablePadding>
          <ListItem
              disablePadding
              onClick={handleOpenLoginDialog}
          >
            <ListItemButton>
              <ListItemText>
                {t('global.actions.signIn')}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
              onClick={handleOpenRegisterDialog}
              disablePadding
          >
            <ListItemButton>
              <ListItemText>
                {t('global.actions.singUp')}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <DialogLogin
            isOpenDialog={isOpenLoginDialog}
            setIsOpenDialog={setIsOpenLoginDialog}
            willShowForgotPassword={willShowForgotPassword}
            setWillShowForgotPassword={setWillShowForgotPassword}
            willShowRegisterDialog={willShowRegisterDialog}
            setWillShowRegisterDialog={setWillShowRegisterDialog}
            onLoginSuccess={loginSuccessHandler}
            preFillEmail={preFillEmail}
            setPreFillEmail={setPreFillEmail}
            showRegisterDialog={() => {
              setIsOpenRegisterDialog(true);
            }}
            showForgotPasswordDialog={() => {
              setIsOpenForgotDialog(true);
            }}
        />
        <DialogForgotPassword
            isOpenDialog={isOpenForgotDialog}
            setIsOpenDialog={setIsOpenForgotDialog}
            setWillShowForgotPassword={setWillShowForgotPassword}
            willShowLoginDialog={willShowLoginDialog}
            setWillShowLoginDialog={setWillShowLoginDialog}
            showLoginDialog={() => {
              setIsOpenLoginDialog(true);
            }}
        />
        <DialogRegister
            preFillEmail={preFillEmail}
            setPreFillEmail={setPreFillEmail}
            isOpenDialog={isOpenRegisterDialog}
            setIsOpenDialog={setIsOpenRegisterDialog}
            setWillShowRegisterDialog={setWillShowRegisterDialog}
            onRegisterSuccess={registerSuccessHandler}
            onRegisterFailure={() => {
            }}
            onClose={onCloseHandler}
        />
      </>

  );

}

function UserProfile({user: {userObj}}) {

  const name = `${userObj?.firstName} ${userObj?.lastName}`;
  const email = `${userObj.email}`;
  const profileImg = userObj?.imageUrl;

  const {t} = useTranslate();
  const {handleCloseDrawer} = useContext(MobileNavContext);

  const {handleUserLogout} = useLogout();

  const [menuEl, setMenuEl] = useState(null);

  const isOpenMenu = Boolean(menuEl);

  const handleToggleMenu = (event) => {
    setMenuEl(menuEl ? null : event.currentTarget);
  };

  function handleCloseMenu() {
    setMenuEl(null);
  }

  function handleSignOut() {
    handleCloseMenu();
    handleCloseDrawer();
    handleUserLogout();
  }

  return (
      <>
        <Box
            sx={{
              px: 2,
              py: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
        >
          <ProfileImageViewer
              size={'sm'}
              imgPath={profileImg}
              placeholderStyle={'user-icon'}
          />
          <Stack direction={'row'} justifyContent={'space-between'}
                 alignItems={'flex-end'}>
            <Stack mt={1}>
              <Typography variant={'h6'}>
                {name}
              </Typography>
              <Typography variant={'body2'}>
                {email}
              </Typography>
            </Stack>
            <IconButton
                size={'small'}
                onClick={handleToggleMenu}
            >
              <ArrowDropDown/>
            </IconButton>
          </Stack>
        </Box>
        <Menu
            open={isOpenMenu}
            onClose={handleCloseMenu}
            anchorEl={menuEl}
            placement={'bottom-end'}
        >
          <MenuItem onClick={handleSignOut}>{t(
              'global.actions.logout')}</MenuItem>
        </Menu>
      </>
  );
}

function NavList({
                   navItems,
                   isAuthenticated,
                   component = 'nav',
                   disablePadding = false,
                   paddingLeft = '',
                 }) {
  return (
      <List
          sx={{width: '100%'}}
          component={component}
          disablePadding={disablePadding}
      >
        {
            navItems?.length > 0 &&
            navItems.filter(x => isAuthenticated || !!x?.isPublicRoute).
                map((item, index) => {
                  if (!item.subMenuItems) {
                    return <NavListItem key={index} item={item}
                                        paddingLeft={paddingLeft}/>;
                  }
                  if (item.subMenuItems) {
                    return <NavListItemWithSubMenu
                        isAuthenticated={isAuthenticated} key={index}
                        item={item}/>;
                  }
                })
        }
      </List>
  );
}

function NavListItem({item, paddingLeft, children}) {

  const Icon = item.icon;
  const navigateTo = useNavigate();
  const {handleCloseDrawer} = useContext(MobileNavContext);

  return (
      <ListItemButton
          sx={{pl: paddingLeft}}
          onClick={() => {
            if (item.route) {
              navigateTo(item.route);
            }
            if (item.onClick) {
              item.onClick();
            }
            handleCloseDrawer();
          }}
      >
        {/* <ListItemIcon>
          <Icon/>
        </ListItemIcon> */}
        <ListItemText primary={item.name}/>
        {children}
      </ListItemButton>
  );
}

function NavListItemWithSubMenu({item, isAuthenticated}) {
  const [isCollapsed, setIsIsCollapsed] = useState(false);

  function toggleCollapse(e) {
    e.stopPropagation();
    setIsIsCollapsed(prevState => !prevState);
  }

  return (
      <>
        <NavListItem item={item}>
          {
            <IconButton onMouseDown={(e) => {
              e.stopPropagation();
            }} onClick={toggleCollapse}>
              {isCollapsed ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          }
        </NavListItem>
        <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
          <NavList
              navItems={item.subMenuItems}
              disablePadding={true}
              isAuthenticated={isAuthenticated}
              component={'div'}
              paddingLeft={4}
          />
        </Collapse>
      </>
  );
}

export default SiteMobileNav;
