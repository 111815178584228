import {validate} from 'email-validator';
import {ERR_NETWORK_ERROR} from '../constants/errors';
import {
  customersBookingsCustomers_GET,
  customersSearchInterConnected_GET,
} from '../vendor/redux/actions/bookings';
import {
  customersClubsExtrasList_GET,
} from '../vendor/redux/actions/clubs-extras';
import {
  createOpenMatch_POST,
  getOpenMatchDetails_GET,
  getOpenMatchList_GET,
  getPlayerRating_GET,
  registerToOpenMatch_POST,
  setAppData,
  setCurrentUser,
  setExtras,
  setInvalidRating,
  setListingData,
  setRange,
  setSingleMatchDetails,
  setSubmitData,
  setSuggestedPlayers,
  setViewGenderErrAlert,
} from '../vendor/redux/actions/openMatches';
import {store} from '../vendor/redux/configure-store';
import {groupByKey, mergeObjects} from './ui';
import AllIconGender from '../assets/icons/IconAllGender.svg';
import IconMale from '../assets/icons/IconMenOnly.svg';
import IconFemale from '../assets/icons/IconWomenOnly.svg';
import IconMixed from '../assets/icons/IconMixedGender.svg';
import {USER_LOGIN} from '../vendor/redux/constants';

export const defaultRow = {
  email: '',
  firstName: '',
  lastName: '',
  imageUrl: '',
  gender: '',
  team: null,
  isBooker: false,
  isAvailable: true,
  isAdded: false,
  isAvailableFor: '', // 'male','female'
};

export const createArray = (props) => {
  const {
    setValue, length, userObj, isCreate, sport, successFn = () => {
    },
  } = props;

  const team = length / 2;

  const arr = Array.from({length}).map((x, i) => {

    if (i == 0 && userObj && isCreate) {

      const {
        email,
        firstName,
        lastName,
        gender,
        id,
        imageUrl,
        ratingList,
      } = userObj;

      const rating = (ratingList &&
          ratingList[sport?.toLowerCase()]?.toFixed(2)) ?? null;

      const toReturn = {
        email,
        firstName,
        lastName,
        gender,
        playerId: id,
        imageUrl,
        isBooker: true,
        isAvailable: false,
        team: i < team ? 1 : 2,
        isAdded: true,
        rating,
        slotNo: i + 1,
      };

      setCurrentUser({
        ...toReturn,
        isRegistered: true,
        canAddPartner: true,
        canRegister: true,
      });
      return toReturn;
    }

    return {...defaultRow, team: i < team ? 1 : 2, slotNo: i + 1};
  });

  setValue('players', [...arr]);
  successFn(arr, userObj);
};

export const isNumberInRange = (params, range) => {
  const [min, max] = range;
  if (params == null)
    return false;

  return Number(params) >= Number(min) && Number(params) <= Number(max);
};

const checkUserRating = (ratingList, sport) => {

  if (ratingList == null || Object?.keys(ratingList)?.length == 0) {
    setInvalidRating(true);
  } else {
    const currentSportRating = ratingList[sport?.toLowerCase()];

    if (currentSportRating && currentSportRating != null) {
      setInvalidRating(false);
    } else {
      setInvalidRating(true);
    }
  }

};

export const getLoggedInPlayerRating = (
    clubId, defaultUser = null, sport, successFn = () => {
    }) => {

  const user = defaultUser ?? store.getState().app.user;

  getPlayerRating_GET(clubId, cbSuccess, cbFail);

  function cbSuccess({data: {data}}) {

    let obj = {};

    const groupData = groupByKey(data, 'sport');
    const ratingsPerSport = groupData[sport?.toLowerCase() ?? ''];

    if (data?.length) {
      data?.map(x => {
        obj = {
          ...obj,
          [x?.sport]: x?.ratingValue,
        };
      });
    }

    let userObj = user?.userObj;
    userObj['ratingList'] = obj ?? {};

    const ratingDetails = ratingsPerSport?.at(0)?.ratingDetails;
    const marks = ratingDetails?.map(
        x => ({...x, value: x?.rating, label: x?.rating?.toString()}));

    setRange({marks});

    const updatedUser = {...user, userObj};
    localStorage.setItem('user', JSON.stringify(updatedUser));
    store.dispatch({type: USER_LOGIN, payload: updatedUser});
    checkUserRating(obj, sport);
    successFn(obj);
  }

  function cbFail(err) {
    console.log(' !! ERRR :', err);
  }

};

export const socketEmitCalculatePrice = (props) => {

  const {
    socket,
    socketId,
    matchId,
    watched,
    currentUser,
    isCreate,
    isRegistered,
  } = props;

  setAppData({loading: true});
  const matchType = watched?.matchType;
  const allPlayers = watched?.players;
  const players = allPlayers?.filter(x => !x.isBooker);

  const userAddedByCurrent = playersAddedByCurrentUser(currentUser, players);
  const usersArrayAddedByCurrent = isRegistered && userAddedByCurrent?.length ?
      userAddedByCurrent :
      undefined;

  const matchData = { // will be null in case of an existing open match
    'courtId': watched?.courtId,
    'date': watched?.date,
    'startTime': watched?.startTime,
    'endTime': watched?.endTime,
  };

  const body = {
    type: 'open_match',
    openMatchId: !isCreate ? matchId : null, // will be null in case of open match creation
    openMatchType: matchType,
    openMatchData: isCreate ? matchData : null,
    extras: watched?.extras?.map((x) => ({...x, quantity: x.count})),
    user: usersArrayAddedByCurrent?.map(x => ({email: x.email})) ?? [],

    wallet: watched?.wallet,
    allPayment: watched?.paymentPreference == 'all', // will only work during open match creation
  };
  socket.emit('calculate_price', socketId, body);

};

export const handleNewPrice = (data, matchType, currentUserRating) => {

  const {maxRating, minRating} = data;

  const storeData = store?.getState().openMatches;
  const isRangeChangedByUser = storeData?.matchRange?.isChangedByUser;

  const priceData = {
    slotPrice: data?.slotPrice,
    court: data?.courtPrice,
    extrasTotal: data?.extrasTotal,
    discount: data?.discountAmount,
    platformPercentage: data?.platformPercentage,
    platformFee: data?.platformFee,
    totalPrice: data?.totalPrice,
    playersPaidFor: data?.playersPaidFor,
    walletUsed: data?.walletUsed,
  };

  setAppData({priceData, loading: false});
  let obj = {
    value: [minRating, currentUserRating, maxRating],
    prevValue: [minRating, currentUserRating, maxRating],
  };
  if (matchType != 'competitive') {
    // obj.minDistance=(+maxRating-(+minRating))?.toFixed(2);
    obj.minDistance = (+(currentUserRating + 0.1) -
        (+currentUserRating - 0.1))?.toFixed(2);
  }

  if (!isRangeChangedByUser || matchType == 'competitive') {
    setRange(obj);
  }
};

const isSameGender = (props) => {

  const {bothUserGenders, genderToCheck} = props;
  return bothUserGenders.every(x => x == genderToCheck);
};

export const checkAvailibityOnGender = ({matchGender, gender, teamMember}) => {
  // If no teammate, just check individual gender matches
  if (!teamMember) {
    if (matchGender === 'mixed') {
      return {
        isAvailable: gender === 'male' || gender === 'female',
        message: '',
      };
    }
    // New condition for gender mismatch alert
    if ((matchGender === 'male' && gender === 'female')) {
      return {isAvailable: false, message: 'This match is for Men only.'};
    }
    if ((matchGender === 'female' && gender === 'male')) {
      return {isAvailable: false, message: 'This match is for Women only.'};
    }
    return {
      isAvailable: matchGender === 'all' || matchGender === gender,
      message: '',
    };
  }

  const teamMemberGender = teamMember.gender;
  const bothUserGenders = [gender, teamMemberGender];

  // Handle different match gender requirements
  switch (matchGender) {
    case 'all':
      return {isAvailable: true, message: ''};
    case 'male':
    case 'female':
      return isSameGender({
        bothUserGenders,
        genderToCheck: matchGender,
      })
          ? {isAvailable: true, message: ''}
          : {
            isAvailable: false,
            message: `This match is for ${
                matchGender == 'male' ? 'Men' : 'Women'
            } only.`,
          };
    case 'mixed':
      return {isAvailable: gender !== teamMemberGender, message: ''};
    default:
      return {isAvailable: false, message: 'Invalid match gender.'};
  }
};

export const checkAvailibity = (props) => {
  const {
    matchGender,
    matchRating,
    playerToAdd,
    players,
    enqueueSnackbar,
    t,
    newUserJoining,
  } = props;

  const {
    email,
    firstName,
    gender,
    id,
    imageUrl,
    lastName,
    rating,
    team,
  } = playerToAdd;

  const teamMember = players?.find(
      x => x.team == team && x?.isAdded && x.email != email);

  if (isNumberInRange(rating, matchRating)) {
    if (newUserJoining && matchGender != 'all' && gender == 'other') {
      setViewGenderErrAlert(true);
      return false;
    }

    const {isAvailable, message} = checkAvailibityOnGender({
      matchGender,
      gender,
      teamMember,
    });

    if (isAvailable) {
      return true;
    } else {
      enqueueSnackbar(message || t('openMatches.page.addJoinPlayer.genderErr'),
          {variant: 'error'});
      return false;
    }
  } else {
    enqueueSnackbar(t('openMatches.page.addJoinPlayer.ratingErr'),
        {variant: 'error'});
    return false;
  }
};

const findPlayerInAllCustomers = (inputValue, sport, playersAdded) => {

  if (!validate(inputValue))
    return;

  store.dispatch(customersBookingsCustomers_GET(inputValue, cbSuccess, cbFail,
      'open-match', sport));

  function cbSuccess({data: {data}}) {
    console.log('customersBookingsCustomers_GET success', data);
    const foundExistingCustomer = data.length > 0;

    if (foundExistingCustomer) {

      const toReturn = data?.map(x => {
        const rating = getRatingOnSport({user: {ratingList: x.ratingList}},
            sport);
        return {...x, rating};
      });

      setSuggestedPlayers({
        list: sortSuggestedPlayers(toReturn, playersAdded),
        hasNoBuddies: true,
        loading: false,
      });
    } else {
      setSuggestedPlayers({list: [], loading: false});
    }

  }

  function cbFail(err) {
    console.log('customersBookingsCustomers_GET fail', err);
    setSuggestedPlayers({loading: false});
  }

};

function sortArrayByBooleanKey(arr, keyName, booleanKey) {
  return arr.sort((a, b) => {
    // First, sort by the booleanKey (true values should come first)
    if (a[booleanKey] === b[booleanKey]) {
      // If the boolean values are the same, sort by keyName (lexicographically)
      return a[keyName].localeCompare(b[keyName]);
    }
    // Otherwise, sort by the booleanKey (true comes first)
    return b[booleanKey] - a[booleanKey];
  });
}

export const isAlreadyExist = (players, player) => {

  const existingPlayer = players.find(x => x.email == player.email);
  return existingPlayer;
};

export const sortSuggestedPlayers = (suggesstedPlayers, addedPlayers) => {

  let copyArr = [...suggesstedPlayers];

  copyArr = copyArr.map(x => isAlreadyExist(addedPlayers, x) ?
      {...x, isAdded: true} :
      {...x, isAdded: false});

  const sortedArray = sortArrayByBooleanKey(copyArr, 'email', 'isAdded');
  return sortedArray;

};

export function handleSearchPlayer(props) {

  const {inputValue, clubId, sport, playersAdded} = props;

  setSuggestedPlayers({loading: true});
  store.dispatch(
      customersSearchInterConnected_GET(inputValue, clubId, cbSuccess, cbFail,
          'customer', 'open-match', sport));

  function cbSuccess({data: {data}}) {
    console.log('customersBookingsCustomers_GET success', data);
    const foundExistingCustomer = data.length > 0;

    if (!validate(inputValue)) {

      const toReturn = data?.map(x => {
        const rating = getRatingOnSport(x, sport);
        return {...x.user, rating};
      });

      setSuggestedPlayers({
        list: sortSuggestedPlayers(toReturn, playersAdded),
        hasNoBuddies: false,
      });
    } else {
      findPlayerInAllCustomers(inputValue, sport, playersAdded);
    }

    setSuggestedPlayers({loading: false});
  }

  function cbFail(err) {
    console.log('customersBookingsCustomers_GET fail', err);
    setSuggestedPlayers({loading: false});
  }
}

export const addPlayerToState = (props) => {

  const {
    t,
    setValue,
    prevState,
    player,
    index,
    team,
    setIsOpen,
    enqueueSnackbar,
    newUserJoining,
  } = props;

  let updatedIndex = index;

  const existingPlayer = prevState.find(x => x.email == player.email);
  if (existingPlayer) {

    return enqueueSnackbar(t('openMatches.create.page.addPlayer.duplicate'),
        {variant: 'error'});
  }

  if (prevState?.length > 2) {
    updatedIndex = team == 2 ? (index == 0 ? 2 : index + team) : index;
  } else if (prevState?.length == 2) {
    updatedIndex = team == 2 ? (index == 0 ? 1 : index + team) : index;
  }

  const updatedState = [...prevState];

  updatedState[updatedIndex] = {
    ...defaultRow, ...player,
    team,
    paymentStatus: 'unpaid',
    slotNo: updatedIndex + 1,
  };
  setValue('players', updatedState);
  if (newUserJoining) {
    setCurrentUser({isRegistered: true});
  }

  setIsOpen(false);

};

export const removePlayerFromState = (props) => {

  const {
    setValue,
    prevState,
    index,
    team,
    currentUser,
    isRemovingSelf,
    matchParticipants,
  } = props;

  const updatedState = [...prevState];
  let updatedIndex = index;

  if (prevState?.length > 2) {
    updatedIndex = team == 2 ? (index == 0 ? 2 : index + team) : index;
  } else if (prevState?.length == 2) {
    updatedIndex = team == 2 ? (index == 0 ? 1 : index + team) : index;
  }

  updatedState[updatedIndex] = {...defaultRow, slotNo: updatedIndex + 1, team};

  if (isRemovingSelf) {
    // if user removing himself from joining then all players added by him will also removed
    const isParticipant = (email) => matchParticipants?.find(
        x => x?.user?.email == email);
    const addedPlayerIndex = updatedState?.findIndex(
        x => x?.addedBy == currentUser?.id && !isParticipant(x.email));
    if (addedPlayerIndex != -1) {
      updatedState[addedPlayerIndex] = {...defaultRow, team};
    }

    setCurrentUser({...currentUser, ...defaultRow, isRegistered: false});
  }

  setValue('players', updatedState);

};

export function fetchExtras(props) {

  const {clubId, courtSport} = props;

  setExtras({loading: true});
  store.dispatch(
      customersClubsExtrasList_GET(clubId, courtSport, cbSuccess, cbFail));

  function cbSuccess({data: {data}}) {
    console.log('customersClubsExtrasList_GET Success', data);
    setExtras({list: data});
    setExtras({loading: false});
  }

  function cbFail(e) {
    console.log('customersClubsExtrasList_GET fail', e);
    setExtras({loading: false});
  }

}

const checkoutHandler = (url, clubId) => {

  if (url) {
    window.location.href = url;
    return;
  }

};

export const playersAddedByCurrentUser = (currentUser, players) => {

  const playersAddedByCurrent = players?.filter(
      (x) => x.addedBy == currentUser?.id && !x?.isAlreadyRegistered)?.
      map((x) => ({email: x.email, team: x.team.toString(), slotNo: x.slotNo}));

  return playersAddedByCurrent;
};

export const handleValidSubmit = (props) => {
  const {
    data,
    allowCourtUpdate,
    clubId,
    navigateTo,
    enqueueSnackbar,
    t,
    isJoining,
    currentUser,
  } = props;

  let domain = process.env.REACT_APP_URL;
  let redirectUrl = `${domain}/club/${clubId}/openMatches/payment/status`;
  let cancelUrl = `${domain}/club/${clubId}/openMatches`;

  const playersAddedByCurrent = playersAddedByCurrentUser(currentUser,
      data?.players);

  setSubmitData({loading: true});

  const createPayload = {
    matchData: {
      type: data?.matchType,
      minRating: data?.minRating,
      maxRating: data?.maxRating,
      gender: data?.gender,
      date: data?.date,
      startTime: data?.startTime,
      endTime: data?.endTime,
      sport: data?.sport,
      courtId: data?.courtId,
      clubId: clubId,
      allowCourtUpdate,
    },
    registerData: {
      team: currentUser?.team,
      slotNo: currentUser?.slotNo,
      extras: data?.extras?.map((x) => ({...x, quantity: x.count})),
      fullPayment: data?.paymentPreference == 'all',
      walletPayment: data?.wallet,
      players: data?.players.filter(
          (player) => !!player?.isAdded && !player?.isBooker,
      ),
      redirectUrl,
      cancelUrl,
    },
  };

  const joinPayload = {
    id: data?.matchId,
    walletPayment: data?.wallet,
    team: currentUser?.team,
    players: playersAddedByCurrent,
    extras: data?.extras?.map((x) => ({...x, quantity: x.count})),
    redirectUrl,
    cancelUrl,
  };

  if (!!isJoining) {
    return registerToOpenMatch_POST(joinPayload, cbSuccess, cbFail);
  }

  return createOpenMatch_POST(createPayload, cbSuccess, cbFail);

  function cbSuccess(res) {
    const response = res?.data?.data;
    const checkout_url = response?.payment?.checkout_url;
    const status = response?.registrationStatus;
    const id = response?.openMatchId; // id in case of wallet payment;

    if (status == 'paid') {
      navigateTo(`/club/${clubId}/openMatches/payment/status?id=${id}`);
    }
    checkoutHandler(checkout_url, status);
  }

  function cbFail(res) {
    const err = res?.response?.data?.data || ERR_NETWORK_ERROR;
    setSubmitData({loading: false});
    enqueueSnackbar(t(err), {variant: 'error'});
  }
};

export const getOpenMatchDetails = (id, setValue) => {

  setSingleMatchDetails({loading: true});
  getOpenMatchDetails_GET(id, cbSuccess, cbFail);

  function cbSuccess(res) {

    const response = res?.data?.data;
    setSingleMatchDetails({data: response, loading: false});

    const {
      billing,
      courtSize,
      date,
      endTime,
      gender,
      id,
      maxRating,
      minRating,
      pricePerSlot,
      shortUuid,
      sport,
      startTime,
      status,
      type,
    } = response;

    setValue('paymentPreference', 'all');

    setValue('courtSize', courtSize);
    setValue('date', date);
    setValue('endTime', endTime);
    setValue('gender', gender);
    setValue('matchId', id);
    setValue('maxRating', maxRating);
    setValue('minRating', minRating);
    setValue('pricePerSlot', pricePerSlot);
    setValue('shortUuid', shortUuid);
    setValue('sport', sport);
    setValue('startTime', startTime);
    setValue('status', status);
    setValue('matchType', type);
    setValue('defaultExtras', billing?.extrasList);

  }

  function cbFail(res) {
    setSingleMatchDetails({loading: false});
  }

};

// LISTING

export const getListingData = props => {

  const {
    sport,
    page,
    limit,
    clubId,
    gender,
    date,
    rows,
    selectedClubs,
    filters,
    setListingArray,
  } = props;

  const body = {
    sport,
    page,
    limit,
    clubId: clubId ?? selectedClubs?.map(x => x?.value)?.toString(),
    date,
    gender: gender?.map(x => x.value)?.toString(),
  };

  setListingData({loading: true});

  getOpenMatchList_GET(body, cbSuccess, cbFail);

  function cbSuccess(res) {

    const response = res?.data?.data;
    const data = response?.rows;
    const totalPages = response?.totalPages;
    let hasMore = true;
    let toReturn;

    const groupData = groupByKey(data, 'date');
    const isEmpty = Object.keys(groupData)?.length == 0;

    if (isEmpty) {
      toReturn = {...rows};
    } else {
      toReturn = mergeObjects({...rows}, {...groupData});
    }

    if (page + 1 == totalPages) {
      hasMore = false;
    }

    console.log(' @@ SUCCES RESPONSE :', toReturn);
    if (setListingArray) {
      setListingArray(data);
      setListingData({loading: false});
    } else {
      setListingData({data: toReturn, loading: false, totalPages, hasMore});
    }

  }

  function cbFail(res) {
    setListingData({loading: false});
    console.log(' @@ FAILURE  RESPONSE :', res);
  }

};

export const preFilledPlayersArray = (props) => {

  const {
    players,
    sport,
    participants,
    setValue,
    loggedInUser,
    singleMatchDetail,
  } = props;

  let addedPlayers = [];
  let arr = players.map(currentState => {

    const addedPlayer = participants?.find(
        x => x.team == currentState?.team && x?.slotNo == currentState?.slotNo);

    if (addedPlayer) {

      const obj = {
        ...currentState,
        ...addedPlayer.user,
        selfPayment: addedPlayer?.selfPayment,
        addedByPlayerDetail: participants?.find(
            x => x?.user?.id == addedPlayer?.addedBy),
        paymentStatus: addedPlayer?.paymentStatus,
        participantId: addedPlayer?.id,
        team: addedPlayer?.team,
        addedBy: addedPlayer?.addedBy,
        isAdded: true,
        rating: getRatingOnSport(singleMatchDetail ?
            addedPlayer :
            {user: addedPlayer?.user?.userRatings[0]}, sport),
        isAlreadyRegistered: true,
      };

      return obj;
    }

    return currentState;

  });

  setValue('players', arr);
};

export const getRatingOnSport = (addedPlayer, sport) => {

  const ratingList = addedPlayer?.user?.ratingList;
  const rating = (ratingList && ratingList[sport?.toLowerCase()]);

  return rating?.toFixed(2) ?? null;

};

export const genderIcons = {
  'all': AllIconGender,
  'mixed': IconMixed,
  'male': IconMale,
  'female': IconFemale,
};

export const getGendersLabel = (t, courtSize, gender) => {

  const label = {
    'all': t('openMatches.page.listing.filter.genders.all'),
    'male': t('openMatches.page.listing.filter.genders.male'),
    'female': t('openMatches.page.listing.filter.genders.female'),
    'mixed': t('openMatches.page.listing.filter.genders.mixed'),
  };

  if (courtSize != 'Single' && gender == 'mixed') {

    return t('openMatches.page.listing.filter.genders.mixedForDouble');
  }

  return label[gender];
};
