import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem, Stack,
  Typography,
} from '@mui/material';
import useLocale from '../../../hooks/localization/useLocale';
import FlagEnUS from '../../../assets/flags/FlagEnUS';
import FlagNl from '../../../assets/flags/FlagNl';
import FlagDE from '../../../assets/flags/FlagDE';
import {useEffect, useState} from 'react';
import useSetLanguage from '../../../hooks/localization/useSetLanguage';
import {headerEvents} from '../../../utils/analyticsEvents';
import {KeyboardArrowDown} from '@mui/icons-material';
import FlagES from '../../../assets/flags/FlagES';
import FlagTr from '../../../assets/flags/FlagTR';

function SiteLanguageSwitcher() {

  const {locale} = useLocale();
  const setLanguage = useSetLanguage(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleClickMenuItem(key) {
    setLanguage(key);
    handleCloseMenu();
  }

  const flags = {
    en: {flag: FlagEnUS, code: 'English'},
    nl: {flag: FlagNl, code: 'Dutch'},
    de: {flag: FlagDE, code: 'German'},
    es: {flag: FlagES, code: 'Spanish'},
    tr: {flag: FlagTr, code: 'Turkish'},
  };

  const SelectedFlag = flags[locale]?.flag || FlagEnUS;
  const SelectedCode = flags[locale]?.code || 'En';

  const locales = [
    {
      key: 'en',
      label: 'English (US)',
    },
    {
      key: 'nl',
      label: 'Nederlands (NL)',
    },
    {
      key: 'de',
      label: 'German (DE)',
    },
    {
      key: 'es',
      label: 'Spanish (ES)',
    },
    {
      key: 'tr',
      label: 'Turkish (TR)',
    },
  ];

  useEffect(() => {

    if (!!open) {
      headerEvents['clickedOnLanguage']();
    }
  }, [open]);

  return (
      <Box>
        <Stack
            direction='row'
            alignItems={'center'}
            onClick={handleClickOpenMenu}
            sx={{
              cursor: 'pointer',
              backgroundColor: 'white',
              px:2,
              py:.85,
              borderRadius: '32px',
              svg: {
                width: '26px',
                height: '26px',
              },
            }}
        >
          <SelectedFlag/>
          <Typography sx={{pl: 1}} variant="body2">{SelectedCode}</Typography>
          <KeyboardArrowDown color={'action'}/>
        </Stack>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
        >
          {
            locales.map((item, index) => {
              return <ListItem
                  key={index}
                  item={item}
                  flags={flags}
                  onClick={handleClickMenuItem}
              />;
            })
          }
        </Menu>

      </Box>
  );
}

function ListItem({item, flags, onClick}) {
  const {flag: Flag, code} = flags[item.key];

  // const Flag = flags[item.key];
  return (
      <MenuItem
          value={item.key}
          onClick={() => {
            onClick(item.key);
          }}
      >
        <ListItemIcon sx={{
          mr: .5,

          svg: {
            width: '28px',
            height: '28px',
          },
        }}><Flag/></ListItemIcon>
        {/* <Typography variant="body2">{code}</Typography> */}
        {item.label}
      </MenuItem>
  );
}

export default SiteLanguageSwitcher;


