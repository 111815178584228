import {Box, Grid, Stack, Typography} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import ClubInfoNewContact from './ClubInfoNewContact';
import ClubInfoNewSocialLinks from './ClubInfoNewSocialLinks';
import React from 'react';

function ClubInfoNewAbout({clubDetails}) {

  const {t} = useTranslate();

  return (
        <Box>
          <Typography variant={'h5-bold'} mb={2}>
            {t('clubInfo.aboutClub.title')}
          </Typography>
          <Stack  maxWidth={'840px'} direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} flexWrap={'wrap'}>
            <ClubInfoNewContact clubDetails={clubDetails} />
            <ClubInfoNewSocialLinks clubDetails={clubDetails} />
          </Stack>
          <Typography maxWidth={'840px'}>
            {clubDetails?.description}
          </Typography>
        </Box>
  );
}

export default ClubInfoNewAbout;
