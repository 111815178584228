import { Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CardTitle({className,title,chipLabel, variant = 'h5-bold', titleMeta,isEnable=true, route,padding,mb=2}) {

    const navigateTo = useNavigate();
    const theme = useTheme();

    return (
        <Stack
        className={className}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={mb}
        padding={padding}
        >
          <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              onClick={() => {
                if (route) {
                  navigateTo(route);
                }
              }}
              sx={{
                color:!isEnable &&  theme.palette.action.disabled
              }}
          >
            <Stack
            size="small"
            sx={{
                justifyContent:'center',
                alignItems:'center',
                color: isEnable? theme.palette.grey[50] : theme.palette.grey[400] ,
                background: isEnable? theme.palette.success.main: theme.palette.action.disabled,
                borderRadius: "8px",
                padding: "4px 0",
                width: "26px",
                height: "26px",
                fontSize: "16px",
                fontWeight: "800",
            }}>
                {chipLabel}
            </Stack>
            <Typography
                variant={variant}
                sx={{
                  cursor: 'pointer',
                }}
            >
              {title}
            </Typography>
          </Stack>
          {titleMeta}
        </Stack>
    );
}
export default CardTitle;