function IconInfo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
             width={24} {...props} >
          <circle cx="12" cy="12" r="10" opacity=".35" fill="currentColor"/>
          <path
              d="M11,17v-5c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v5c0,0.552-0.448,1-1,1h0C11.448,18,11,17.552,11,17z"
              fill="currentColor"/>
          <circle cx="12" cy="7.5" r="1.5" fill="currentColor"/>
        </svg>
    );
}
export default IconInfo;