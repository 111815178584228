import {
  Alert,
  AlertTitle,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PageCard from "../../_Global/Page/PageCard";
import { useTranslate } from "@tolgee/react";
import useIsMobile from "../../../hooks/ui/useIsMobile";
import DialogSection from "../../_Global/Dialogs/DialogSection";
import {
  AccessTime,
  AccountBalanceWallet,
  Payment,
  PeopleAltOutlined,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import BookingsDateTimeDetails from "../Details/BookingsDateTimeDetails";
import moment from "moment-timezone";
import useExperienceContext from "../../../hooks/clubExperience/useExperienceContext";
import { forwardRef, useEffect, useMemo, useState, useRef } from "react";
import { useFormContext } from "react-hook-form";
import {
  getCourtBookingLength,
} from "../../../utils/bookings";
import genUid from "light-uid";
import useSocket from "../../../hooks/sockets/useSocket";
import { useSnackbar } from "notistack";
import { ERR_NETWORK_ERROR } from "../../../constants/errors";
import { PaymentTypeSelect } from "../Inputs/PaymentTypeSelect";
import PlayersSelect from "../Inputs/PlayersSelect";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useIsAuthenticated from "../../../hooks/access/useIsAuthenticated";
import { bookingEvents } from "../../../utils/analyticsEvents";
import WalletAlerts from "../WalletAlerts";
import WalletSelect from "../../_Global/Wallet/WalletSelect";
import useWalletSelf from "../../../hooks/wallets/useWalletSelf";
import BookingAddEditBilling from "../Details/BookingAddEditBilling";
import AccordionExtras from "../../../dialogs/Booking/AccordionExtras";
import CardTitle from "./CardTitle";
import IconInfo from "../../../assets/icons/IconInfo";

const socketUUID = genUid();

const BookingSection = forwardRef(({
  selectedCourt,
  startDate,
  startTime,
  endTime,
  isCourtNotAvailable,
  extras,
  isLoadingExtras,
  isCourtSelected,
  setIsOpenDialog,
  priceData,
  setPriceData,
  locationState
}, ref) => {
  
  const {clubId} = selectedCourt;
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useIsMobile();
  const theme = useTheme();
  
  const { socket } = useSocket();
  const [searchParams,setSearchParams] = useSearchParams();
  const isSubmitting = searchParams.get('isSubmitting');

  const navigateTo = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { clubPolicies: policies } = useExperienceContext();
  
  const { watch} = useFormContext();
  const watched = watch();
  const watchedPaymentType = watched?.paymentType;
  const watchedPlayers = watched?.players || [];
  const watchedExtras = watched?.extras || [];
  const isPayingWithWallet = watched?.wallet;

  const [isLoadingSocketPrice, setIsLoadingSocketPrice] = useState(false);
  const [booker, setBooker] = useState({});

  const { walletSelf, fetchWalletSelf } = useWalletSelf(clubId);

  let walletData;

  if (walletSelf !== "loading") {
    if (walletSelf) {
      walletData = walletSelf;
    }
  }

  const bookerDiscountApplied = priceData?.users?.find(
    (user) => user.owner
  )?.hasApplicablePackage;

  const players = useMemo(() => {
    return watchedPlayers?.map((player) => {
      return {
        userId: player.id,
        email: player.email,
        owner: player.isOrganizer,
      };
    });
  }, [watchedPlayers]);

  const isLoading = !isCourtSelected || isLoadingExtras || !policies || !priceData || walletSelf == "loading";

  const detailSectionStyles = {
    padding: "24px",
    flexDirection: isMobile?"column":"row",
    flexWrap: "wrap",

    "& .firstContainer": {
      flex: "1 0 50%",
      borderRight: isMobile ? "none" : `1px solid ${theme.palette.divider}`,
      paddingRight: isMobile ? "0" : "1rem",
    },

    "& .secondContainer": {
      flex: "1 0 50%",
      paddingLeft: isMobile ? "0" : "1rem",
    },
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };
  function emitSocketCalculatePrice() {
    setIsLoadingSocketPrice(true);
    const body = {
      version: 2,
      type: "courtBooking",
      courtBooking: {
        ...(isPayingWithWallet && { wallet: true }),
        date: watched?.startDate,
        startTime: watched?.startTime,
        endTime: watched?.endTime,
        courtId: selectedCourt?.courtId,
        paymentType: watched?.paymentType,
      },
      user: players,
      extras: watchedExtras.map((extra) => {
        return {
          extrasId: extra.id,
          quantity: extra.count,
        };
      }),
    };
    console.log("socket calculate_price emit body", body);
    socket.emit("calculate_price", socketUUID, body);
  }

  function handleSocketNewPrice(id, data) {
    if (id === socketUUID) {
      if (data?.status === "error") {
        console.log("Socket new_price error", data);
        enqueueSnackbar(t(data?.message || ERR_NETWORK_ERROR), {
          variant: "error",
        });
        handleCloseDialog();
      } else {
        console.log("Socket new_price data received", data);
        setPriceData(data);
        const organizer = watchedPlayers?.find((x) => x.isOrganizer);

        if (organizer) {
          if (data) {
            const socketUsers = data?.users;
            const socketUser = socketUsers?.find(
              (user) => user.email === organizer.email
            );
            organizer.price = socketUser?.price;
          }

          setBooker({ ...organizer });
        }
      }
      setIsLoadingSocketPrice(false);
    }
  }

  useEffect(() => {
    if (!isSubmitting && watched?.court?.id) {
      const debounceTimeout = setTimeout(() => {
        emitSocketCalculatePrice();
      }, 150);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [
    selectedCourt,
    watched?.court?.id,
    players,
    watchedExtras,
    watchedPaymentType,
    isPayingWithWallet,
  ]);

  // Sockets Listen for price change
  useEffect(() => {
    socket.on("new_price", handleSocketNewPrice);
    //Cleanup
    return () => {
      socket.off("new_price");
    };
  }, []);
  
  return (
    <Grid item lg={8} xs={12} ref={ref} minHeight={'600px'}>
        <PageCard
          sx={{ 
            padding: 0,
            boxShadow:  '0 6px 15.5px 3.5px #dddddd',
            overflow:'hidden',
            borderRadius:isMobile?0:'16px'
          }}
        >
          <CardTitle
            className='bg-gradiant-color'
            title={t("addBooking.dialog.bookingSection.headerText")}
            padding={"24px"}
            mb={0}
            isEnable={isCourtSelected}
            chipLabel={2}
          />
          <Divider />
          {
            isCourtSelected?
            <Stack sx={{ ...detailSectionStyles }}>
            <Stack className="firstContainer">
              {isCourtNotAvailable && !isLoading && (
                <Alert severity={"error"} sx={{ mt: 2 }}>
                  <AlertTitle>{t("bookings.error.noaccess.title")}</AlertTitle>
                  {t("bookings.error.noaccess.description")}
                </Alert>
              )}
              <DialogSection
                isLastChild={true}
                label={t("bookingAdd.time")}
                icon={AccessTime}
                isLoading={isLoading}
              >
                <BookingsDateTimeDetails
                  isLoading={isLoading}
                  date={moment(startDate, "YYYY-MM-DD").format("ddd ll")}
                  timeStart={moment(startTime, "HH:mm").format("LT")}
                  timeEnd={moment(endTime, "HH:mm").format("LT")}
                />
              </DialogSection>

              <AccordionExtras
              label={t("bookingAdd.extra")}
              loading={isLoading}
              extras={extras}
              />

              <DialogSection
                isLastChild={true}
                label={t("bookingAdd.payment")}
                icon={Payment}
                isLoading={isLoading}
                isDisabled={isCourtNotAvailable || !isCourtSelected}
              >
                <PaymentTypeSelect
                  discountApplied={bookerDiscountApplied}
                  disableSplitOption={false}
                  isLoading={isLoading}
                />
              </DialogSection>
              <DialogSection
                isLastChild={true}
                label={t("bookingAdd.players")}
                icon={PeopleAltOutlined}
                isLoading={isLoading}
                isDisabled={isCourtNotAvailable || !isCourtSelected}
              >
                <PlayersSelect
                  locationState={locationState}
                  id={clubId}
                  isLoading={isLoading}
                  isEnabled={!isCourtNotAvailable || isCourtSelected}
                  allowChangeBooker={!isAuthenticated}
                  disableRemoveBooker={!isAuthenticated}
                  priceTotal={priceData?.totalPrice || 0}
                  socketPriceData={priceData}
                  isLoadingSocketPrice={isLoadingSocketPrice}
                  navigateTo={() =>
                    navigateTo(`/club/${clubId}/add-booking`, {
                      state: {
                        ...locationState,
                        extras: watched?.extras,
                        ...watched,
                        selectedCourt
                      },
                    })
                  }
                  reloadWindow={() => window.location.reload()}
                  singlePlayerErrorMessage={t(
                    "global.components.playerSelect.error.singlePayment"
                  )}
                  discountApplied={bookerDiscountApplied}
                  clubId={clubId}
                  isPayingWithWallet={isPayingWithWallet}
                  handleCloseBookingDialog={handleCloseDialog}
                />
              </DialogSection>
            </Stack>
            <Stack className="secondContainer">
              {isAuthenticated && (
                <DialogSection
                  isLastChild={true}
                  label={t("bookings.add.section.wallet")}
                  subLabel={
                    policies?.wallet && (
                      <Link
                        style={{
                          cursor: "pointer",
                          margin: "0 0 0 auto",
                          color: theme.palette.primary.main,
                        }}
                        color={theme.palette.secondary.main}
                        target="_blank"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_URL}/club/${clubId}/wallet/topup?cId=${clubId}`,
                            "_blank"
                          )
                        }
                      >
                        {t("wallet.topup")}
                      </Link>
                    )
                  }
                  icon={AccountBalanceWallet}
                  isLoading={isLoading}
                  isDisabled={isCourtNotAvailable || !isCourtSelected}
                >
                  <WalletSelect
                    isLoading={isLoading}
                    walletData={{
                      available: priceData?.wallet?.available,
                      canPay: priceData?.wallet?.canPay,
                      amount: +priceData?.wallet?.amount || 0,
                    }}
                    onChange={() => bookingEvents["useWalletFromAdd"]()}
                  />
                  {!isLoading && (
                    <WalletAlerts
                      canPay={priceData?.wallet?.canPay}
                      clubId={clubId}
                      onSuccess={() => emitSocketCalculatePrice()}
                      onSuccessParam={clubId}
                      walletId={walletData?.id}
                      players={players}
                      watchedExtras={watchedExtras}
                    />
                  )}
                </DialogSection>
              )}
              <DialogSection
                isLoading={isLoading}
                label={t("bookingAdd.billings")}
                icon={ReceiptLongOutlined}
                isLastChild={true}
                isDisabled={isCourtNotAvailable || !isCourtSelected}
              >
                <BookingAddEditBilling
                  isLoading={isLoading}
                  priceData={priceData}
                  walletPayment={isPayingWithWallet}
                  bookerPayment={booker?.price}
                  playerCount={watchedPlayers.length}
                  paymentType={watchedPaymentType}
                  courtBookingLength={getCourtBookingLength(startTime, endTime)}
                />
              </DialogSection>
            </Stack>
            </Stack>:
            <Stack
            height={'400px'}
            justifyContent={'center'}
            alignItems={'center'}
            >
              <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} rowGap={1}>
                <IconInfo/>  
                <Typography variant="subtitle2" color={theme.palette.grey[600]} >
                  {t('booking.warning.toSelectCourtFirst')}
                </Typography>
              </Stack>
            </Stack>
          }
        </PageCard>
    </Grid>
  );
});
export default BookingSection;
