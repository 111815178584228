import {
  AssignmentTurnedInOutlined,
  EventOutlined,
  Inventory2Outlined,
  Person,
} from '@mui/icons-material';
import useHasAccess from '../../../hooks/access/useHasAccess';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PasswordIcon from '@mui/icons-material/Password';
import {useTranslate} from '@tolgee/react';
import {useParams} from 'react-router-dom';
import IconWallet from '../../../assets/icons/IconWallet';
import IconMyClubs from '../../../assets/icons/IconMyClubs';
import IconSettings from '../../../assets/icons/IconSettings';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';

function useNavItems(experience) {

  const {t} = useTranslate();
  const {clubId,companyId} = useParams();
  const isAuthenticated = useIsAuthenticated();

  const navItems = [
    // {
    //   name: t('sideBar.link.textHome'),
    //   route: `/club/${clubId}`,
    //   icon: false,
    //   disabled: false,
    //   canAccess: true,
    //   isPublicRoute:true
    // },
    {
      name: t('sideBar.link.allClubEvents'),
      route: `/club/${clubId}/events`,
      icon: EventOutlined,
      disabled: false,
      canAccess: true,
      isPublicRoute:true
    },
    {
      name: t('global.breadcrumbs.training'),
      route: `/club/${clubId}/trainings`,
      icon: EventOutlined,
      disabled: false,
      canAccess: useHasAccess('trainings'),
      isPublicRoute:true
    },
    {
      name: t('global.breadcrumbs.tournaments'),
      route: `/club/${clubId}/tournaments`,
      icon: EventOutlined,
      disabled: false,
      canAccess: useHasAccess('tournaments'),
      isPublicRoute:true
    },
    {
      name: t('sideBar.link.textOpenMatches'),
      route: `/club/${clubId}/openMatches`,
      icon: false,
      disabled: false,
      canAccess: useHasAccess('open_matches'),
      isPublicRoute:true
    },
    {
      name: t('sideBar.link.allClubOffers'),
      route: `/club/${clubId}/all-clubs/offers`,
      icon: EventOutlined,
      disabled: false,
      canAccess: true,
      isPublicRoute:true
    },
    {
      icon: Person,
      name: t('settings.playerModule.myActivity'),
      route: `/club/${clubId}/myActivity`,
      canAccess: isAuthenticated,
    },
    {
      name: t('sideBar.link.wallet'),
      route: `/club/${clubId}/wallet`,
      icon: IconWallet,
      disabled: false,
      canAccess: false,
      isProfileSection:true
    },
    {
      name: t('sideBar.link.muClubs'),
      route: `/club/${clubId}/my-clubs`,
      icon: IconMyClubs,
      disabled: false,
      canAccess: false,
      isProfileSection:true
    },
    {
      name: t('sideBar.link.textSetting'),
      route: `/club/${clubId}/settings`,
      icon: IconSettings,
      disabled: false,
      canAccess: false,
      isProfileSection:true,
      subMenuItems: [
        {
          icon: Person,
          name: t('settings.playerModule.profileName'),
          route: `/club/${clubId}/settings/profile`,
          canAccess: true,
        },
        {
          name: t('sideBar.link.textBooking'),
          route: `/club/${clubId}/bookings`,
          activeOnRoot: true,
          icon: DateRangeIcon,
          disabled: false,
          canAccess: true,
        },
        {
          name: t('sideBar.link.textEvents'),
          route: `/club/${clubId}/myEvents`,
          icon: EventOutlined,
          disabled: false,
          canAccess: true,
        },
        {
          name: t('sideBar.link.textTrainings'),
          route: `/club/${clubId}/myTrainings`,
          icon: EventOutlined,
          disabled: false,
          canAccess: useHasAccess('trainings')
        },
        {
          icon: PasswordIcon,
          name: t('settings.playerModule.passwordName'),
          route: `/club/${clubId}/settings/change-password`,
          canAccess: true,
        },
        {
          icon: Inventory2Outlined,
          name: t('settings.playerModule.packages'),
          route: `/club/${clubId}/settings/packages`,
          canAccess: true,
        },
        {
          icon: AssignmentTurnedInOutlined,
          name: t('sideBar.link.transactions'),
          route: `/club/${clubId}/settings/transactions`,
          canAccess:true
        }
      ],
    }
  ];

  const companyNavItems = [
    {
      name: t('company.expe.nav.book'),
      route: `/company/${companyId}`,
      icon: false,
      disabled: false,
      canAccess: true,
      isPublicRoute:true
    },
    {
      name: t('sideBar.link.allClubEvents'),
      route: `/company/${companyId}/events`,
      icon: EventOutlined,
      disabled: false,
      canAccess: true,
      isPublicRoute:true
    },
    {
      name: t('global.breadcrumbs.training'),
      route: `/company/${companyId}/trainings`,
      icon: EventOutlined,
      disabled: false,
      canAccess: true,
      isPublicRoute:true
    },
    {
      name: t('sideBar.link.textOpenMatches'),
      route: `/company/${companyId}/openMatches`,
      icon: false,
      disabled: false,
      canAccess: true,
      isPublicRoute:true
    },
    {
      name: t('sideBar.link.allClubOffers'),
      route: `/company/${companyId}/offers`,
      icon: EventOutlined,
      disabled: false,
      canAccess: true,
      isPublicRoute:true
    }
  ];
  return experience=='COMPANY'? companyNavItems: navItems;

}

export default useNavItems;
