import * as axios from 'axios';
import {persistor, store} from '../redux/configure-store';
import {RESET_REDUX_STATE, USER_LOGOUT} from '../redux/constants';
import { showVersionMismatchPopup } from '../redux/actions/search';

export default function setupAxios() {
console.log('process.env.BUILD_VERSION',process.env.REACT_APP_BUILD_VERSION, process.env.REACT_APP_ENV_DOMAIN)
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.timeout = 50000;
  axios.defaults.headers.common['x-clubos-channel'] = 'CLUBOS-WEB';
  axios.defaults.headers.common['x-clubos-domain'] = 'PADELOSCO';
  axios.defaults.headers.common['version'] =  process.env.REACT_APP_BUILD_VERSION;

  axios.interceptors.request.use(
      (config) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.accessToken) {
          config.headers.Authorization = `Bearer ${user?.accessToken}`;
        }
        return config;
      },
      err => Promise.reject(err),
  );

  axios.interceptors.response.use(
      response => {
        const versionUpdate = response?.headers?.version;
        if(versionUpdate === 'true') {
          store.dispatch(showVersionMismatchPopup(true));
        }
        else {
          store.dispatch(showVersionMismatchPopup(false));
        }
        return response;
      },
      err => {
        if (err?.response?.status === 401) {
          const hadMasterAccess = !!localStorage.getItem('masterAccess');
          localStorage.clear();
          if (hadMasterAccess) {
            localStorage.setItem('masterAccess', 'true');
          }

          store.dispatch({type: RESET_REDUX_STATE});
          store.dispatch({type: USER_LOGOUT});
          persistor.purge();

          const currentUrl = window.location.href;
          const parts = currentUrl.split('/');
          const clubIndex = parts.findIndex(part => part === 'club');

          if (clubIndex !== -1 && clubIndex < parts.length - 1) {
            const clubId = parts[clubIndex + 1];
            window.location = `/club/${clubId}`
          }
        }

        return Promise.reject(err);
      },
  );

  return axios;

}

