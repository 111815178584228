import { Box, Link, Stack, Typography } from "@mui/material";
import InvoiceHeader from "./InvoiceHeader/InvoiceHeader";
import useExperienceContext from "../../hooks/clubExperience/useExperienceContext";
import { useTranslate } from "@tolgee/react";

const InvoicePreview=({children,receiptId})=>{

    const {t} = useTranslate();
    const {clubDetails} = useExperienceContext();

    return(
        <Box
        display={'none'}
        >
            <Stack id="pdfInvoice" gap={1}>
                <InvoiceHeader 
                id={receiptId}
                />
                {children}
                <Typography
                fontWeight={700}
                mt={1}
                p={'12px'}
                >
                    {t('invoice.footer.text')}
                    &nbsp;
                    <Link>
                        {clubDetails?.email}
                    </Link>
                </Typography>
            </Stack>
        </Box>
    )
}
export default InvoicePreview;