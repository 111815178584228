import {Box, Grid, Stack} from '@mui/material';
import React from 'react';
import ClubInfoNewLocation from './ClubInfoNewLocation';
import ClubInfoNewTiming from './ClubInfoNewTiming';
import ClubInfoNewAmenities from './ClubInfoNewAmenities';
import ClubInfoNewAbout from './ClubInfoNewAbout';
import ClubInfoNewEvents from './ClubInfoNewEvents';
import ClubInfoNewOffers from './ClubInfoNewOffers';
import usePolicies from '../../hooks/policies/usePolicies';
import ClubInfoNewClubLinks from './ClubInfoNewClubLinks';
import PackageSlider from '../Packages/Slider/PackageSlider';
import ClubInfoOpenMatchesList from '../OpenMatches/ClubInfoOpenMatchesList';
import PageCard from '../_Global/Page/PageCard';
import UpcomingBookingsList from '../Bookings/UpcomingBookingsList';
import useUser from '../../hooks/access/useUser';
import SlotSearchV2 from '../SlotSearchV2/SlotSearchV2';
import ClubInfoNewTrainings from './ClubInfoNewTrainings';
import useBreakpoint from '../../hooks/ui/useBreakpoint';

function ClubInfoNew({
                       clubId,
                       clubDetails,
                       clubTimings,
                       type,
                       bookings,
                       totalBookings,
                       getUpcomingBooking,
                     }) {

  const user = useUser();
  const userId = user?.userObj?.id;
  const policies = usePolicies();
  const clubWalletsEnabled = policies?.wallet;

  const {isMdDown} = useBreakpoint();

  return (
      <Box
          sx={{
            display: 'flex',
            columnGap: '24px',
            rowGap: '24px',
            flexWrap: 'wrap',

            '.item': {
              flex: 1,
              minWidth: 'calc(50% - 12px)',
              ...(isMdDown && {
                minWidth: '100%',
              }),

              '&.full-width': {
                minWidth: '100%',
              },

            },
          }}
      >

        {
            userId && bookings?.length > 0 &&
            <Box className={'item full-width'}>
              <PageCard
                  sx={{
                    mb: 3,
                  }}
              >
                <UpcomingBookingsList
                    bookings={bookings}
                    totalBookings={totalBookings}
                    clubId={clubId}
                    getUpcomingBooking={getUpcomingBooking}/>
              </PageCard>
            </Box>
        }

        <SlotSearchV2/>
        <ClubInfoOpenMatchesList alwaysMobileView={true}/>
        <ClubInfoNewEvents clubId={clubId} type={type} policies={policies}/>
        <ClubInfoNewTrainings/>

        <Box className={'item full-width'}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={clubWalletsEnabled ? 6 : 12}>
              <PageCard>
                <PackageSlider/>
              </PageCard>
            </Grid>
            {
                clubWalletsEnabled &&
                <Grid item xs={12} md={6}>
                  <PageCard>
                    <ClubInfoNewOffers
                        clubId={clubId}
                        clubDetails={clubDetails}
                        type={type}
                    />
                  </PageCard>
                </Grid>
            }
          </Grid>

        </Box>

        <Box className={'item full-width'}>
          <PageCard>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>

                  <ClubInfoNewAbout clubDetails={clubDetails}/>
                  <ClubInfoNewClubLinks links={clubDetails?.links}/>
                  <ClubInfoNewAmenities clubId={clubId} direction={'row'}
                                        isIcon={true}/>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack spacing={3}>
                  <ClubInfoNewTiming clubTimings={clubTimings}/>
                  {/* <ClubInfoNewLocation clubId={clubId} height={'254px'}/> */}
                </Stack>
              </Grid>
            </Grid>
          </PageCard>
        </Box>

      </Box>
  );

}

export default ClubInfoNew;


