import {
  Box,
  Divider,
  ListItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useMemo, useState} from 'react';
import ProfileImageViewer from '../Profile/ProfileImageViewer';
import useUser from '../../../hooks/access/useUser';
import useLogout from '../../../hooks/onboarding/useLogout';
import useNavItems from '../../../layouts/LayoutClub/Nav/useNavItems';
import {Link} from 'react-router-dom';
import IconLogout from '../../../assets/icons/IconLogout';
import { Close, MenuRounded } from '@mui/icons-material';
import IconMenu from '../../../assets/icons/IconMenu';

function SiteUserProfileClub({
                               type,
                               size = 'xs',
                               onLogoutSuccess = () => {
                               },
                               experience,
                             }) {

  const theme = useTheme();
  const {t} = useTranslate();

  const selectedUser = useUser();
  const userObject = selectedUser?.userObj;

  const navItems = useNavItems(experience);

  const {handleUserLogout} = useLogout(onLogoutSuccess);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  const filteredNavItems = useMemo(
      () => navItems.filter(x => !!x?.isProfileSection), [navItems]);

  const UserProfileImage = ({size = 'xs'}) => {

    return (
        <ProfileImageViewer
            size={size}
            imgPath={userObject?.imageUrl}
            placeholderStyle={'user-icon'}
        />
    );
  };

  const ListItem = ({
                      text, icon: Icon, route, onClick = () => {
    },
                    }) => {

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            columnGap={1}
            padding={'14px 16px'}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                background: theme.palette.grey[200],
              },
            }}
            component={route && Link}
            to={route}
            onClick={onClick}
        >
          {Icon && <Icon fill={theme.palette.primary.main}
                         sx={{fill: theme.palette.primary.main}}
                         width={'20px'}/>}

          <Typography variant="body2" color="text.primary">
            {text}
          </Typography>
        </Stack>
    );
  };

  return (<Box
      sx={{
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
      }}
  >
    <Box
        sx={{
          cursor: 'pointer',
          width:'32px'
        }}
        onClick={handleOpenMenu}
    >
      {/* <UserProfileImage size={size}/> */}
      {
        anchorEl==null?
        <IconMenu sx={{fontSize:'30px',color: '#fff'}}/>:
        <Close sx={{fontSize:'30px',color: '#fff'}}/>
      }
    </Box>
    <Popover
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: 2,
            background: theme.palette.background.default,
            minWidth: '345px',
            padding: '24px 16px',
            borderRadius: '12px',
          },
        }}
    >
      <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
      >
        <UserProfileImage
            size="lg"
        />
        <Stack
            alignItems={'center'}
        >
          <Typography variant={'h6-bold'}>
            {`${userObject?.firstName || ''} ${userObject?.lastName || ''}`}
          </Typography>
          <Typography variant={'body2'}>
            {userObject?.email}
          </Typography>
        </Stack>
      </Stack>
      <Stack
          sx={{
            background: '#fff',
            borderRadius: '16px',
            mt: '1.5rem',
          }}
      >
        {
          filteredNavItems?.map((navItem, index) => {
            const isLast = index == filteredNavItems?.length - 1;

            return (
                <>
                  <ListItem
                      text={navItem?.name}
                      icon={navItem?.icon}
                      route={navItem?.route}
                      onClick={handleCloseMenu}
                  />
                  {
                      !isLast &&
                      <Divider/>
                  }
                </>
            );
          })

        }
      </Stack>
      <Stack
          sx={{
            background: '#fff',
            borderRadius: '16px',
            mt: '1rem',
            cursor: 'pointer',
          }}
      >
        <ListItem
            icon={IconLogout}
            text={t('authFlow.logout')}
            onClick={() => {
              handleCloseMenu();
              handleUserLogout();
            }}
        />
      </Stack>

    </Popover>

  </Box>);
}

export default SiteUserProfileClub;
