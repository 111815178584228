import {Chip, Tooltip} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {getLocalizedNum} from '../../../utils/localization';
import useLocale from '../../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import useDefaults from '../../../hooks/defaults/useDefaults';

function ChipBookingStatus({
                             bookingType,
                             bookingStatus,
                             bookingPrice,
                             openMatchStatus,
                             chipStyle = 'soft' || 'outlined' || 'filled',
                             variant = 'booking-status' || 'booking-price',
                             size = 'small' || 'medium',
                           }) {

  const {t} = useTranslate();
  const {currencySymbol} = useDefaults();
  const {locale} = useLocale();

  dayjs.locale(locale);

  let isReservationType = bookingType === 'recurrence' || bookingType ===
      'reservation';

  let isNonReservationType = bookingType === 'maintenance' || bookingType ===
      'closed' || bookingType === 'training' || bookingType === 'tournament';

  let isEventType = bookingType === 'event';
  let isOpenMatch = bookingType === 'open_match';

  const isCancelled = bookingStatus === 'cancelled';

  let color;
  let label = '';
  let tooltipTitle = '';

  if (isCancelled) {
    color = 'default';
    label = t('global.chips.label.cancelled');
  }

  if (isReservationType) {

    const isPaid = bookingStatus === 'paid';
    const isUnpaid = bookingStatus === 'unpaid';

    if (isPaid) {
      color = 'success';
    }

    if (isUnpaid) {
      color = 'error';
    }

    if (variant === 'booking-status') {

      if (isPaid) {
        label = t('global.chips.label.paid');
      }

      if (isUnpaid) {
        label = t('global.types.status.unpaid');
      }

    }

    if (variant === 'booking-price') {

      if (isPaid) {
        label = `${currencySymbol}${getLocalizedNum(locale,
            +bookingPrice)}`;
        tooltipTitle = t('global.chips.label.paid');
      }

      if (isUnpaid) {
        label = `${currencySymbol}${getLocalizedNum(locale,
            +bookingPrice)}`;
        tooltipTitle = t('global.types.status.unpaid');
      }

    }

  }

  if (isNonReservationType) {
    color = 'success';
    label = t('global.chips.label.active');
  }

  if (isEventType) {
    color = 'success';
    label = t('global.chips.label.active');
  }

  if (isOpenMatch) {

    const isBooked = openMatchStatus === 'booked';
    const isPaid = bookingStatus === 'paid';
    const isUnpaid = bookingStatus === 'unpaid';

    if (isPaid) {
      color = 'success';
    }

    if (isUnpaid) {
      color = 'error';
    }

    if (variant === 'booking-price') {

      if (isPaid) {
        label = t('global.chips.label.paid');
      }

      if (isUnpaid) {
        label = t('global.types.status.unpaid');
      }

    }

    if (variant === 'booking-status') {
      if (isBooked) {
        color = 'success';
      } else {
        color = 'error';
      }

      if (isBooked) {
        label = t('openMatches.detail.page.court.status.booked');
        tooltipTitle = t('openMatches.detail.page.court.status.booked');
      } else {
        label = t('openMatches.detail.page.court.status.notBooked');
        tooltipTitle = t('openMatches.detail.page.court.status.notBooked');
      }
    }

  }

  return (
      <Tooltip
          title={!!tooltipTitle ? tooltipTitle : ''}
          placement={'top'}
          arrow
      >
        <Chip
            size={size}
            label={label}
            color={color}
        />
      </Tooltip>
  );

}

export default ChipBookingStatus;


