import {Box, Stack, Typography} from '@mui/material';
import ProfileImageViewer
  from '../../../components/_Global/Profile/ProfileImageViewer';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import useExperienceContext
  from '../../../hooks/clubExperience/useExperienceContext';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';
import ContainerFixed
  from '../../../components/_Global/Containers/ContainerFixed';
import SiteOnboardingButtons
  from '../../../components/_Global/Site/SiteOnboardingButtons';
import SiteUserProfileClub
  from '../../../components/_Global/Site/SiteUserProfileClub';
import {LayoutClubNavHorizontal} from '../Nav/LayoutClubNavHorizontal';
import {useMemo} from 'react';
import useIsMobile from '../../../hooks/ui/useIsMobile';
import SiteMobileNav from '../../../components/_Global/Site/SiteMobileNav';
import useWebViewMobile from '../../../hooks/access/useWebViewMobile';

function LayoutCLubHeaderV3({defaultPath, experience = 'CLUB', navItems}) {

  const {pathname} = useLocation();
  const {clubId} = useParams();
  const isAuthenticated = useIsAuthenticated();
  const isMobile = useIsMobile();
  const isWebViewMobile = useWebViewMobile();
  const homePath = defaultPath ?? `/club/${clubId}`;
  const isHome = pathname === homePath;

  const showDesktopNavigation = useMemo(() => {
    if (isMobile) {
      return false;
    }
    if (!isAuthenticated && isHome) {
      return false;
    }
    return true;
  }, [isHome, isMobile, isAuthenticated]);

  return (
      <ContainerFixed>
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              height: '120px',
            }}
        >
          {/*Logo*/}
          <Logo
              homeUrl={homePath}
              experience={experience}
              disabled={isWebViewMobile}
              inverted={true}
          />

          {/*Nav*/}
          <Stack direction="row" spacing={3} alignItems="center">
            {
                showDesktopNavigation &&
                <LayoutClubNavHorizontal navItems={navItems} inverted={true}/>
            }
            {
                isMobile && !isWebViewMobile &&
                <Box
                    sx={{
                      ml: 'auto',
                    }}
                >
                  <SiteMobileNav color={'action'} navItems={navItems}/>
                </Box>
            }
            {
                !isMobile &&
                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                >
                  {
                      isAuthenticated &&
                      <SiteUserProfileClub
                          size={'sm'}
                          experience={experience}
                      />
                  }
                  {
                      !isAuthenticated && !isWebViewMobile &&
                      <SiteOnboardingButtons
                          size={'large'}
                          showDivider={false}
                          variantRegister={'outlined'}
                          variantSignIn={'contained'}
                          disableRegister={true}
                          signInInverted={true}
                          colorSignin={'secondary'}
                          inverted={true}
                      />
                  }
                </Box>
            }
          </Stack>
        </Stack>
      </ContainerFixed>
  );

}

function Logo({experience, homeUrl, disabled, inverted}) {

  const isCompanyExperience = experience === 'COMPANY';

  const navigateTo = useNavigate();
  const {clubId} = useParams();

  const {clubDetails, companyDetails} = useExperienceContext();
  const details = isCompanyExperience ? companyDetails : clubDetails;

  return (
      <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1.75}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            if (disabled)
              return;
            navigateTo(homeUrl);
          }}
      >
        <ProfileImageViewer
            size={'sm'}
            imgPath={details?.imageUrl}
            placeholderStyle={'shield-icon'}
        />
        <Typography
            variant={'h5-bold'}
            lineHeight={1.1}
            whiteSpace={'nowrap'}
            mb={.5}
            sx={{
              fontWeight:700,
              fontSize: {
                md: '24px',
                xs: '18px',
              },
            ...(inverted && {
              color:"#fff"
            })
            }}
        >
          {details?.name}
        </Typography>
      </Stack>
  );

}

export default LayoutCLubHeaderV3;
