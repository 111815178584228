import {
  AccessTimeOutlined,
  LocationOn,
  PeopleAltOutlined,
} from "@mui/icons-material";
import DialogSection from "../../_Global/Dialogs/DialogSection";
import { useTranslate } from "@tolgee/react";
import dayjs from "dayjs";
import { formatTime } from "../../../utils/ui";
import BookingsDateTimeDetails from "../../Bookings/Details/BookingsDateTimeDetails";
import BookingsClubDetails from "../../Bookings/Details/BookingsClubDetails";
import BookingsParticipantsDetails from "../../Bookings/Details/BookingsParticipantsDetails";
import BillingDetails from "../../_Global/Billing/BillingDetails";
import { roundToTwoDecimal } from "../../../utils/math";
import {
  getBookingTimeHourSuffix,
  getCourtBookingLength,
  getExtrasBillingEntries,
} from "../../../utils/bookings";
import { Box, Stack, useTheme } from "@mui/material";
import InvoiceHeader from "../InvoiceHeader/InvoiceHeader";

const BookingDetailInvoice = ({
  booking,
  isLoading,
  isPayingWithWallet,
  participants,
  billingData,
}) => {
  const { t } = useTranslate();
  const theme = useTheme();

  const date = booking?.date;
  const startTime = booking?.startTime;
  const endTime = booking?.endTime;

  const clubName = booking?.court.club.name;
  const clubStreet = booking?.court?.club?.addresses[0]?.streetAddress;
  const houseNumber = booking?.court?.club?.addresses[0]?.houseNumber;
  const clubCity = booking?.court?.club?.addresses[0]?.city;
  const clubPostalCode = booking?.court?.club?.addresses[0]?.postalCode;

  const paymentType = booking?.paymentType;

  const bookingTotalPrice = +billingData?.totalPrice;
  const platformFee = billingData?.platformFee;
  const platformPercentage = billingData?.platformPercentageFormatted;
  const discount = +billingData?.discount || 0;
  const hasPayedWithWallet = billingData?.paidViaWallet;
  const hasPayedWithCounter = billingData?.paidViaCounter;

  const bookingLength = getCourtBookingLength(startTime, endTime);
  const bookingLengthSuffix = getBookingTimeHourSuffix(bookingLength);

  const isMinutes = bookingLength < 1;

  const billingEntries = [
    {
      label: `${t("global.components.billing.court")} ${
        isMinutes
          ? bookingLength * 60
          : roundToTwoDecimal(bookingLength * 10) / 10
      } ${
        isMinutes
          ? t("global.components.billing.min")
          : t(`global.components.billing.hour${bookingLengthSuffix}`)
      }`,
      amount: billingData?.court,
    },
    ...getExtrasBillingEntries(billingData),
  ];

  return (
    <Stack gap={1}>
      <Box
        border={`1px solid ${theme.palette.divider}`}
        borderRadius={"12px"}
        padding={"12px"}
      >
        <DialogSection label={t("bookingAdd.time")} isLoading={isLoading}>
          <BookingsDateTimeDetails
            isLoading={isLoading}
            date={date && `${dayjs(date, "YYYY-MM-DD").format("ddd ll")}`}
            timeStart={startTime && formatTime(startTime)}
            timeEnd={endTime && formatTime(endTime)}
          />
        </DialogSection>

        <DialogSection isLoading={isLoading} label={"Club"}>
          <BookingsClubDetails
            isLoading={isLoading}
            clubName={clubName}
            clubStreet={clubStreet}
            houseNumber={houseNumber}
            clubCity={clubCity}
            clubPostalCode={clubPostalCode}
          />
        </DialogSection>

        <DialogSection isLoading={isLoading} label={t("bookingAdd.players")} isLastChild={true}>
          <BookingsParticipantsDetails
            isLoading={isLoading}
            players={participants}
            isPayingWithWallet={isPayingWithWallet}
            platformFee={platformFee}
            paymentType={paymentType}
            showProfileImage={false}
          />
        </DialogSection>
      </Box>
      <Box
        border={`1px solid ${theme.palette.divider}`}
        borderRadius={"12px"}
        padding={"12px"}
      >
        <DialogSection
          label={t("bookingAdd.billings")}
          isLoading={isLoading}
          isLastChild={true}
        >
          <BillingDetails
            isLoading={isLoading}
            entries={billingEntries}
            discount={discount}
            platformPercentage={platformPercentage}
            platformFee={platformFee}
            isWalletPayment={hasPayedWithWallet}
            isCounterPayment={hasPayedWithCounter}
            walletDebitedAmount={billingData?.walletPaidAmount}
            counterPaidAmount={billingData?.counterPaidAmount}
            totalBillingAmount={bookingTotalPrice}
          />
        </DialogSection>
      </Box>
    </Stack>
  );
};
export default BookingDetailInvoice;
