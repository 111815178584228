import {Box, Grid, Stack, Typography} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {
  customersClubsAminitesList_GET,
} from '../../vendor/redux/actions/scheduleApis';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Check} from '@mui/icons-material';

function ClubInfoNewAmenities({clubId,direction='column' , isIcon = false }) {

  const {t} = useTranslate();
  const dispatch = useDispatch();

  const [amenities, setAmenities] = useState(null);

  const availableAmenities = useMemo(() => {
    if (amenities) {
      return amenities?.filter(amenity => amenity?.value === 'true');
    }
  },[amenities]);


  const fetchAndSetAmenities = () => {

    function cbSuccess(res) {
      console.log('customersClubsAminitesList_GET Success', res);
      setAmenities(res?.data?.data);
    }

    function cbFail(e) {
      console.log('customersClubsAminitesList_GET Fail', e);
    }

    dispatch(customersClubsAminitesList_GET(clubId, cbSuccess, cbFail));
  };

  useEffect(() => {
    fetchAndSetAmenities();
  }, []);

  return (
      <Box>
        <Typography variant="h6-bold" mb={3}>
          {t('clubsDetail.amenitiesHeading')}
        </Typography>
        {
          availableAmenities?.length > 0 &&
            <Grid container spacing={3}>
              {
                  availableAmenities.map((amenity, index) => {
                      return (
                          <Grid item md={3} xs={6}>
                            <Stack direction={'row'} columnGap={1} align={'center'}>
                           {/* {isIcon &&   <Check fontSize={'small'} color={'action'}/> } */}
                              <Typography
                                  key={index}
                                  variant="body2"
                                  fontWeight={400}
                                  color={'text.primary'}
                              >
                                {t(amenity?.alias)}
                              </Typography>
                            </Stack>

                          </Grid>
                      );
                  })
              }
            </Grid>
        }
        {
            availableAmenities?.length === 0 &&
            <Typography >
              {t('club.noAmenities')}
            </Typography>
        }
      </Box>
  );
}

export default ClubInfoNewAmenities;
