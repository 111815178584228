import jsPDF from "jspdf";
import { store } from "../vendor/redux/configure-store";

export const downloadPDF = ({containerId='pdfInvoice', fileName='download.pdf', orientation='p' || "l"}) => {
    
    const clubDetails = store.getState().app.selectedClub;
    const clubMail = clubDetails?.email;

    const element = document.getElementById(containerId);
    if(!element)
        return;

    const pdf = new jsPDF(orientation, "px", "a4"); // Portrait, points, A4 format
      
    pdf.html(element, {
      callback: function (pdf) {

        pdf.save(fileName);
      },
      x: (pdf.internal.pageSize.getWidth() - Math.min(700, pdf.internal.pageSize.getWidth() - 15)) / 2, // Center the content
      y: 5, // Small top margin
      width: Math.min(700, Math.min(pdf.internal.pageSize.getWidth() - 15, 700)), // Ensure width does not exceed 700
      windowWidth: 1000, // Ensure scaling
    });
};